<template>
  <div class="ma-2">
    <v-dialog v-model="dialogs.delete" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text>
          Are you sure? Do you want to delete this file?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadings.delete"
            color="error"
            @click="processDelete()"
            >Yes</v-btn
          ><v-btn color="secondary" text @click="dialogs.delete = false"
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <slot name="title">My Peeps File</slot>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        hide-details
        append-icon="mdi-magnify"
      ></v-text-field>
    </v-card-title>
    <v-container>
      <v-data-table
        :options.sync="options"
        :search="search"
        :loading="loadings.table"
        :items="files"
        :headers="headers"
      >
        <template v-slot:[`header.feature`]="{ header }"
          >{{ header.text }}
          <table-filter
            defaultColor=""
            v-model="docFilter"
            :items="featuresList"
          ></table-filter>
        </template>

        <template v-slot:[`item.filename`]="{ item }">
          <span>
            {{ item.filename }}
          </span>
        </template>
        <template v-slot:[`item.feature`]="{ item }">
          <v-chip
            small
            @click="setDocumentFilter(item.feature)"
            v-if="item.feature && getFeature(item.feature)"
            :color="getFeature(item.feature).style.color"
            :dark="getFeature(item.feature).style.dark"
            >{{ getFeature(item.feature).text }}</v-chip
          >
        </template>
        <template v-slot:[`item.parcelID`]="{ item }">
          <ParcelIdToNumber :id="item.parcelID || ''" />
        </template>
        <template v-slot:[`item.size`]="{ item }">
          {{ formatBytes(item.size) }}
        </template>
        <template v-slot:[`item.timeCreated`]="{ item }">
          {{ fromNow(item.timeCreated) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon color="primary" @click="newWindow(item.url, item.filename)"
            >mdi-download</v-icon
          >
          <v-icon @click="showDelete(item)" color="error">mdi-trash-can</v-icon>
        </template>
      </v-data-table>
    </v-container>
    <div class="mt-3">
      <UploadFiles @upload="getFiles" />
    </div>
    <div class="mt-3">
      <doc-scanner doc="tax bill" :showDetail="docsCount == 0"></doc-scanner>
    </div>
  </div>
</template>

<script>
import { storageRef } from '@/firebase/db'
import user from '@/mixins/user'
import Moment from 'moment'
import utils from '@/mixins/utils'
import parcel from '@/mixins/parcel'
import filesize from 'filesize'

//components
import ParcelIdToNumber from '../../components/Parcel/IdToNumber.vue'

import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  mixins: [user, utils, parcel],
  components: {
    DocScanner: () => import('@/components/DocScanner'),
    UploadFiles: () => import('@/components/File/UploadFiles'),
    ParcelIdToNumber
  },
  data: () => ({
    loading: true,
    deleteFile: null,
    dialogs: {
      delete: false
    },
    loadings: {
      table: false,
      delete: false
    },
    search: '',
    options: { sortBy: ['timeCreated'], sortDesc: [true] }
  }),
  computed: {
    headers() {
      const headers = [
        { text: 'File', value: 'filename' },
        { text: 'Description', value: 'description' },
        { text: 'Feature', value: 'feature' },
        { text: 'Uploaded', value: 'timeCreated' },
        { text: 'Size', value: 'size' },
        { text: 'Actions', value: 'action' }
      ]
      const parcelsCount = this.$store.state.parcel.parcels.length
      if (parcelsCount > 1) {
        headers.splice(2, 0, {
          text: 'Parcel',
          value: 'parcelID'
        })
      }
      return headers
    },
    docsCount() {
      let count = 0
      count = this.$store.getters.documents.length
      return count
    },
    docFilter: {
      get() {
        return this.$store.getters.documentFilter
      },
      set(value) {
        this.$store.commit('setDocumentFilter', _.lowerCase(value))
      }
    },
    featuresList() {
      let features = new Set()

      this.$store.state.documents.map(e => {
        features.add(_.lowerCase(e.feature))
      })
      features = Array.from(features)

      features = features
        .map(e => {
          return this.getFeature(e)
        })
        .filter(e => !!e)
        .map(e => {
          return { ...e, value: e.feature }
        })

      features.unshift({ text: 'All', value: '' })
      features.push({ text: 'N/A', value: 'na' })

      return features
    },
    ...mapGetters({
      files: 'documents'
    })
  },
  async mounted() {
    this.getFiles()
    const u = await this.currentUser()
    this.getParcels(u.uid)
  },
  methods: {
    formatBytes(b) {
      const res = filesize(b)
      if (res.split(' ').pop() === 'KB') {
        return Number(res.split(' ')[0]).toFixed(0) + ' KB'
      }
      return res
    },
    async getFileInfo(file) {
      let data = { url: await file.getDownloadURL() }
      let meta = await file.getMetadata()
      let custom = {}
      if ('customMetadata' in meta) {
        custom = { ...meta.customMetadata }
      }
      if (custom.deleted) {
        return null
      }
      data.filename = custom.filename || ''
      data.feature = custom.feature || ''
      data.timeCreated = meta.timeCreated
      data.description = custom.description || ''
      data.size = meta.size
      data.parcelID = custom.parcelID
      return data
    },
    async getFiles() {
      this.loadings.table = true

      let user = await this.currentUser()
      let list = await storageRef.ref(user.uid).listAll()

      let tmpFiles = []

      list.items.forEach(async file => {
        tmpFiles.push(this.getFileInfo(file))
      })

      let results = await Promise.all(tmpFiles.map(p => p.catch(e => e)))
      results = results.filter(i => !(i instanceof Error)).filter(i => !!i)

      this.$store.commit('documents', results)
      this.loadings.table = false
    },
    format(date) {
      return Moment(date).format('lll')
    },
    showDelete(item) {
      this.deleteFile = item
      this.dialogs.delete = true
    },
    async processDelete() {
      this.loadings.delete = true
      let ref = await storageRef.refFromURL(this.deleteFile.url)
      let meta = { ...(await ref.getMetadata()) }
      if (!meta.customMetadata) {
        meta.customMetadata = {}
      }
      meta.customMetadata.deleted = true
      this.removeFileFromParcel(
        this.deleteFile.url,
        this.deleteFile.feature,
        meta.customMetadata.parcelID
      )
      await ref.updateMetadata(meta)
      this.dialogs.delete = false
      this.loadings.delete = false
      this.getFiles()
    },
    showFile(url) {
      this.newWindow(url)
    },
    setDocumentFilter(feature) {
      if (this.docFilter) {
        this.docFilter = ''
      } else {
        this.docFilter = feature
      }
    }
  }
}
</script>

<style></style>
