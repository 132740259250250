var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.delete),callback:function ($$v) {_vm.$set(_vm.dialogs, "delete", $$v)},expression:"dialogs.delete"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title'),_c('v-card-text',[_vm._v(" Are you sure? Do you want to delete this file? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loadings.delete,"color":"error"},on:{"click":function($event){return _vm.processDelete()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogs.delete = false}}},[_vm._v("No")]),_c('v-spacer')],1)],1)],1),_c('v-card-title',[_vm._t("title",[_vm._v("My Peeps File")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-container',[_c('v-data-table',{attrs:{"options":_vm.options,"search":_vm.search,"loading":_vm.loadings.table,"items":_vm.files,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.feature",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)+" "),_c('table-filter',{attrs:{"defaultColor":"","items":_vm.featuresList},model:{value:(_vm.docFilter),callback:function ($$v) {_vm.docFilter=$$v},expression:"docFilter"}})]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.filename)+" ")])]}},{key:"item.feature",fn:function(ref){
var item = ref.item;
return [(item.feature && _vm.getFeature(item.feature))?_c('v-chip',{attrs:{"small":"","color":_vm.getFeature(item.feature).style.color,"dark":_vm.getFeature(item.feature).style.dark},on:{"click":function($event){return _vm.setDocumentFilter(item.feature)}}},[_vm._v(_vm._s(_vm.getFeature(item.feature).text))]):_vm._e()]}},{key:"item.parcelID",fn:function(ref){
var item = ref.item;
return [_c('ParcelIdToNumber',{attrs:{"id":item.parcelID || ''}})]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBytes(item.size))+" ")]}},{key:"item.timeCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.timeCreated))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.newWindow(item.url, item.filename)}}},[_vm._v("mdi-download")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.showDelete(item)}}},[_vm._v("mdi-trash-can")])]}}],null,true)})],1),_c('div',{staticClass:"mt-3"},[_c('UploadFiles',{on:{"upload":_vm.getFiles}})],1),_c('div',{staticClass:"mt-3"},[_c('doc-scanner',{attrs:{"doc":"tax bill","showDetail":_vm.docsCount == 0}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }