import { parcelRef } from './firestore'

class Parcel {
  constructor() {
    this.ref = parcelRef
    this.fields = {
      parcelNumber: 'number'
    }
  }

  delete(id) {
    return this.ref.doc(id).delete()
  }

  getNumber(id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve('')
      }
      this.ref
        .doc(id)
        .get()
        .then(result => {
          if (result.exists) {
            resolve(result.data()[this.fields.parcelNumber])
          } else {
            resolve('')
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default Parcel
