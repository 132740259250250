<template>
  <span>
    {{ number }}
  </span>
</template>

<script>
import Parcel from '../../services/parcel'
const parcel = new Parcel()

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      number: ''
    }
  },
  methods: {
    async getParcelNumber() {
      this.number = await parcel.getNumber(this.id)
    }
  },
  watch: {
    id: {
      handler() {
        this.getParcelNumber()
      },
      immediate: true
    }
  }
}
</script>

<style>
</style>